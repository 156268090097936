<template>

    <div class="main">
        <div class="search">
            <div class="refresh" @click="refresh"><i class="fa fa-refresh"></i></div>
            <!--파트너 선택-->
            <partner-selector-comp @setPartnerId="setPartnerId"></partner-selector-comp>
            <!--검색조건선택-->
            <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="applyCash.byField"
                       placeholder="검색조건">
                <el-option :value="managerConst.User.SEARCH_BY_USERNAME" label="아이디"></el-option>
                <el-option :value="managerConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
                <el-option :value="managerConst.User.SEARCH_BY_REALNAME" label="입금명"></el-option>
            </el-select>
            <el-input v-model="applyCash.searchString" size="mini" style="width: 150px;margin-left: 5px"
                      placeholder="검색내용입력"></el-input>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>

            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>

            <!--상태-->
            <el-select size="mini" style="width: 110px;margin-left: 20px" v-model="applyCash.status"
                       @change="search" placeholder="상태선택">
                <el-option :value="null" label="전체">전체</el-option>
                <el-option :value="managerConst.ApplyCash.STATUS_WAITING" label="미확인">미확인</el-option>
                <el-option :value="managerConst.ApplyCash.STATUS_CHECKING" label="확인중">확인중</el-option>
                <el-option :value="managerConst.ApplyCash.STATUS_COMPLITE" label="완료">완료</el-option>
                <el-option :value="managerConst.ApplyCash.STATUS_CANCEL" label="취소">취소</el-option>
            </el-select>

            <el-button size="mini" type="warning" style="margin-left: 20px" @click="downloadhistory">입금다운로드
            </el-button>


        </div>
        <div class="data">
            <div style="padding: 5px 0;font-weight: bold;width: 100%;display: flex;flex-direction: column;align-items: flex-start;justify-content: center">
                <p>회원:{{totalUserCount}}명, 총{{totalCount}}회, 총입금액:{{totalCash |comma}}</p>
                <div>
                    <el-button size="mini" type="primary" style="width: 150px"
                               @click="updateStatusBySelectedIds(managerConst.ApplyCash.STATUS_CHECKING,'확인중처리',managerConst.DISABLE)">
                        선택내역 확인중처리
                    </el-button>
                    <el-button size="mini" type="success" style="margin: 5px 10px;width: 150px"
                               @click="updateStatusBySelectedIds(managerConst.ApplyCash.STATUS_COMPLITE,'성공처리',managerConst.DISABLE)">
                        선택내역 성공처리
                    </el-button>
                    <el-button size="mini" type="danger" style="width: 150px"
                               @click="updateStatusBySelectedIds(managerConst.ApplyCash.STATUS_CANCEL,'취소처리',managerConst.DISABLE)">
                        선택내역 취소처리
                    </el-button>
                </div>
            </div>
            <el-table
                    :data="applyCashList"
                    style="width: 100%"
                    max-height="750"
                    border
                    @selection-change="handleSelectionChange">
                <!--체크박스-->
                <el-table-column
                        fixed
                        type="selection"
                        width="40">
                </el-table-column>
                <el-table-column
                        fixed
                        label="번호"
                        width="80">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="총판"
                        width="80">
                    <template slot-scope="scope">
                        <div v-if="null != scope.row.agent2">
                            {{scope.row.agent2.nickname}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="닉네임"
                        width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user.sticker" style="margin-right: 5px" :style="{color:scope.row.user.scolor}"> {{scope.row.user.sticker}}</span>
                        <el-button size="mini" type="success"
                                   @click="editUser(scope.row.userId,managerConst.MODE_EDIT)"
                                   style="cursor: pointer;padding: 3px 1px">
                            {{scope.row.user.nickname}}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="입금명"
                        width="90">
                    <template slot-scope="scope">
                        {{scope.row.name}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="금액"
                        width="90">
                    <template slot-scope="scope">
                        <i class="fa fa-coins" v-if="scope.row.consiterecharge == managerConst.YES"></i>
                        <span style="display: inline-block;padding: 2px;border-radius: 3px;width: 95%"
                              :class="{'bg-red':scope.row.isrepeatamount == managerConst.YES,'text-blue':scope.row.amount>=100000}">{{scope.row.amount|comma}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="보너스"
                        width="330">
                    <template slot-scope="scope">
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_NOT">[미지급]</span>

                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES1"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: grey">[스포츠] 롤링100%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES2"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: deepskyblue">[미니게임] 롤링500%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES3"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: green">[카지노] 롤링500%</i>
                                                    </span>
                        <span v-if="scope.row.bonusin == managerConst.ApplyCash.APPLY_BONUS_YES4"
                              class="text-primary">
                                                        <i class="fa fa-check-circle" style="color: yellowgreen">[슬롯] 롤링500%</i>
                                                    </span>

                        <div style="display: flex;align-items: center;justify-content: center;flex-wrap: nowrap">
                            <el-input size="mini" v-model="scope.row.memo"></el-input>
                            <el-button size="mini" type="primary" @click="updateMemo(scope.row)">저장</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="통장"
                        width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_BANKCARDID">
                             <span v-if="scope.row.ccrt">{{scope.row.ccrt.title}}</span>
                        </span>
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD"
                              class="text-primary">가상계좌 1</span>
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_2"
                              class="text-danger">가상계좌 2</span>
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_3"
                              class="text-purple">가상계좌 3</span>
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_FACK_CARD_4"
                              class="">가상계좌 4</span>
                        <span v-if="scope.row.user.banktype == managerConst.User.BANK_TYPE_NORMAL">수동</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="신청일짜"
                        width="110">
                    <template slot-scope="scope">
                        {{scope.row.createTime|datef('MM월DD일 HH:mm')}}
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="상태"
                        width="70">
                    <template slot-scope="scope">
                                <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_WAITING"
                                      class="badge badge-primary">미확인</span>
                        <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CHECKING"
                              class="badge badge-primary">확인중</span>
                        <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_COMPLITE"
                              class="text-blue">완료</span>
                        <span v-if="scope.row.status == managerConst.ApplyCash.STATUS_CANCEL" class="text-red">취소</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="Action"
                        width="140">
                    <template slot-scope="scope">
                        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center"
                             v-if="scope.row.status != managerConst.ApplyCash.STATUS_COMPLITE && scope.row.status != managerConst.ApplyCash.STATUS_CANCEL">
                            <el-button size="mini" type="primary" style="width: 80px"
                                       v-if="scope.row.status != managerConst.ApplyCash.STATUS_CHECKING"
                                       @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_CHECKING,'확인중처리',managerConst.DISABLE)">
                                확인중처리
                            </el-button>
                            <el-button size="mini" type="success" style="margin: 5px 0;width: 80px"
                                       @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_COMPLITE,'성공처리',managerConst.DISABLE)"
                            >성공처리
                            </el-button>
                            <el-button size="mini" type="danger" style="width: 80px"
                                       @click="updateStatus(scope.row.id,managerConst.ApplyCash.STATUS_CANCEL,'취소처리',managerConst.DISABLE)">
                                취소처리
                            </el-button>
                        </div>
                        <div  v-if="scope.row.display == managerConst.DISABLE">
                            <el-button
                                       @click="updateDisplay(scope.row.id,managerConst.ENABLE)"
                                       size="mini" type="danger">
                                삭제복구
                            </el-button><br/>
                        </div>
                        <div v-if="scope.row.status == managerConst.ApplyCash.STATUS_COMPLITE && $store.state.manager.role != 0 && $store.state.manager.role != 10">
                            <el-button
                                    @click="updateStatusCancelWrongResult(scope.row.id)"
                                    size="mini" type="warning">
                                취소<br/>
                                (상태만 변경함)
                            </el-button>
                        </div>
                        <div v-if="scope.row.status == managerConst.ApplyCash.STATUS_CANCEL && $store.state.manager.role != 0 && $store.state.manager.role != 10">
                            <el-button
                                       @click="updateStatusCancel2Checking(scope.row.id)"
                                       size="mini" type="primary">
                                `확인중`변경
                            </el-button>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="처리자"
                        width="70">
                    <template slot-scope="scope">
                        <span :class="{'text-red':scope.row.autochecked == managerConst.YES}">{{scope.row.operator}}</span>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 100, 150, 200]"
                    :page-size="20"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>
    </div>

</template>

<script>
    import Vue from "vue";
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {manager} from "../../common/administrator/managerMixin";
    import managerConst from "../../common/administrator/managerConst";
    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";

    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {
        downloadApplyHistory,
        getApplyList,
        updateApplyDisplay, updateApplyMemo,
        updateApplyStatus, updateApplyStatusCancel2Checking,
        updateApplyStatusCancelWrongResult
    } from "../../network/manager/applyRequest";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import fileDownload from "js-file-download";
    Vue.prototype.$userEditToast = UserEditToast

    export default {
        name: "ManagerApplyRecharge",
        components: {DateSelectorComp, PartnerSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        mixins: [manager],
        data() {
            return {
                applyCash: {user: {},ids:[]},
                applyCashList: [],
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                selectedPartnerId: null,
                totalCount: 0,
                totalUserCount: 0,
                totalCash: 0,
                multipleSelection: [],

            }
        },
        methods: {
            refresh(){
                this.startDate = this.$moment().subtract(0, 'days').format('yyyy-MM-DD');
                this.endDate = this.$moment().format('yyyy-MM-DD')
                this.applyCash = {user: {}}
                this.getApplyCashList();
            },
            search() {
                this.getApplyCashList();
            },
            editUser(id, mode) {
                this.$userEditToast({'userId': id, 'mode': mode})
            },
            getApplyCashList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.applyCash.startDate = this.startDate;
                this.applyCash.endDate = this.endDate;
                this.applyCash.applyType = managerConst.ApplyCash.ATYPE_RECHARGE
                getApplyList(this.applyCash, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.total;
                    this.applyCashList = res.data.data.cashList;
                    this.totalCash = res.data.data.totalCash
                    this.totalCount = res.data.data.totalCount
                    this.totalUserCount = res.data.data.totalUserCount
                    loadingInstance.close();
                })
                this.applyCash.ids = [];
            },
            /*단일항목 변경*/
            updateStatus(id, status, text, consiterecharge) {
                this.$confirm(text + '처리 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let record = {}
                    record.id = id
                    record.status = status
                    record.applyType = managerConst.ApplyCash.ATYPE_RECHARGE;
                    record.consiterecharge = consiterecharge;
                    updateApplyStatus(record).then(res => {
                        if (res.data.success) {
                            this.search()
                        } else {
                            this.$message({
                                type: 'error',
                                duration: 1000,
                                message: res.data.msg
                            });
                        }
                        loadingInstance.close()
                    })


                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     duration: 1000,
                    //     message: '취소되였습니다'
                    // });
                });
            },
            /*선택항목 상태변경*/
            updateStatusBySelectedIds(status, text, consiterecharge) {
                if(this.applyCash.ids.length == 0){
                    this.$message({
                        type: 'error',
                        duration: 1000,
                        message: '처리하실 내역을 선택해주세요'
                    });
                    return false;
                }
                this.$confirm(text + '처리 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let loadingInstance = Loading.service({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let record = {}
                    record.ids = this.applyCash.ids
                    record.status = status
                    record.applyType = managerConst.ApplyCash.ATYPE_RECHARGE;
                    record.consiterecharge = consiterecharge;
                    updateApplyStatus(record).then(res => {
                        if (res.data.success) {
                            this.search()
                        } else {
                            this.$message({
                                type: 'error',
                                duration: 1000,
                                message: res.data.msg
                            });
                        }
                        loadingInstance.close()
                    })


                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     duration: 1000,
                    //     message: '취소되였습니다'
                    // });
                });
            },
            updateMemo(row){
                let record = {}
                record.id =row.id;
                record.memo = row.memo;
                if(!record.memo){
                    this.$message({
                        type: 'error',
                        duration: 1000,
                        message: '메모를 입력하세요'
                    });
                    return false;
                }
                updateApplyMemo(record).then(res => {
                    this.search()
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '메모입력이 완료되였습니다'
                    });
                })
            },
            updateDisplay(id, display) {
                updateApplyDisplay({id: id, display: display}).then(res => {
                    this.search()
                })
            },
            updateStatusCancelWrongResult(id) {

                this.$confirm('취소처리 하시겠습니까?', '', {
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateApplyStatusCancelWrongResult(id).then(res => {
                        this.search()
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     duration: 1000,
                    //     message: '취소되였습니다'
                    // });
                });

            },
            updateStatusCancel2Checking(id) {
                this.$confirm('`확인중`상태로 변경 하시겠습니까?', '', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    updateApplyStatusCancel2Checking(id).then(res => {
                        this.search()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '취소되였습니다'
                    });
                });

            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.search();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.applyCash = {}
                this.applyCash.partnerId2 = partnerId
                this.search()
            },
            downloadhistory() {
                downloadApplyHistory(this.applyCash).then(res => {
                    let filename = new Date().getFullYear() + '-'+ new Date().getMonth() + '-' + new Date().getDate() + '-'+new Date().getTime()
                    fileDownload(res.data, '입금내역-' + filename + '.xls')
                })
            },
            handleSelectionChange(val) {
                console.log(val)
                this.multipleSelection = val;
                this.applyCash.ids = [];
                this.multipleSelection.map(g => {
                    this.applyCash.ids.push(g.id)
                });
            },

        },
        created() {
            this.startDate = this.$moment().subtract(0, 'days').format('yyyy-MM-DD');
            this.endDate = this.$moment().format('yyyy-MM-DD')
            this.search()
        },
        watch: {}
    }
</script>

<style scoped>
</style>